import React, {useEffect, useState} from 'react'

import {Steps} from 'antd'
import {PropTypes} from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import {
  GetAllDataSteps,
  GetAllEmployeeOrders
} from '../../infra/requests/CheckoutRequests'
import PageTopPanel from '../../shared/components/panels/PageTopPanel'
import GetImage from '../../shared/components/Image'
import BaseLoading from '../../shared/components/loading/BaseLoading'
import {
  EachTitle,
  OrderRow,
  PageSection,
  PageWrapper,
  TitleWrapper,
  OrderInformation,
  OrdersWrapper,
  InfoItem,
  ExpandButton,
  ProgressBar,
  OrderSummary,
  OrderTable,
  OrderTableWrapper,
  OrderTableTitle,
  OrderTableItems,
  LineText,
  LinkText,
  ProblemMailLink,
  OrderInfoTable,
  SummaryAside,
  SummaryTableItems,
  SummaryTitle,
  SummarySpan,
  OrderStatusAside,
  HalfTable,
  StatusBarBig,
  BigProgressBar,
  ProductOrderRow,
  OrderProduct,
  ProductThumb,
  ProductCategory,
  ProductTitle,
  ProductQuantity,
  ProductStatus,
  ProductPrice,
  OrderAside,
  OrderInfoAside,
  InfoItemDesktop
} from './OrdersStyle'
import LocaleString from '../../infra/constants/LocaleString'
import Currency from '../../infra/utils/Currency'

const {Step} = Steps

const Orders = ({user, translate}) => {
  const [open, setOpen] = useState({id: null, status: false})
  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(true)
  const [regularDataSteps, setRegularDataSteps] = useState()
  const [returnDataSteps, setReturnDataSteps] = useState()
  const [orderCount, setOrderCount] = useState(0)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const {languageId} = user

  // Uncomment when activating orderFunctionality
  // const [orderBy, setOrderBy] = useState('Date')
  const orderBy = 'Date'

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const GetOrders = async () => {
      setLoading(true)

      const {data, success} = await GetAllEmployeeOrders()

      if (success) {
        setOrders(data)

        const result = await GetAllDataSteps()

        if (result.success) {
          setRegularDataSteps(result.data?.regularFlowOrder)
          setReturnDataSteps(result.data?.returnExchangeFlowOrder)

          setLoading(false)
        }
      }

      setOrderCount(data?.length || 0)
    }

    GetOrders()
  }, [])

  useEffect(() => {
    switch (orderBy) {
      case 'OrderID':
        setOrders(orders?.sort((a, b) => b.orderID - a.orderID))
        break
      case 'Status':
        setOrders(
          orders?.sort(
            (a, b) =>
              b.orderStatus.orderStatusID - a.orderStatus.orderStatusID
          )
        )
        break
      case 'Total':
        setOrders(orders?.sort((a, b) => b.totalValue - a.totalValue))
        break
      default:
        setOrders(
          orders?.sort(
            (a, b) =>
              new Date(Date.parse(b.date)) - new Date(Date.parse(a.date))
          )
        )
    }
  }, [orderBy, orders])

  if (loading) {
    return <BaseLoading />
  }

  const getMonthName = (month) => {
    const date = new Date()
    date.setMonth(month)
    const monthName = date
      .toLocaleString(LocaleString[languageId].value, {month: 'short'})
      .replace('.', '')
    return monthName
  }

  const getFullMonth = (month) => {
    const date = new Date()
    date.setMonth(month)
    const monthName = date
      .toLocaleString(LocaleString[languageId].value, {month: 'long'})
      .replace('.', '')
    return monthName
  }

  const formatDate = (obj) => {
    const date = new Date(obj)
    const month = date.getMonth()
    const monthName = getMonthName(month)
    const fullMonthName = getFullMonth(month)
    const dayOfWeek = date.getDay()
    const dayOfMonth = date.getDate()
    const year = date.getFullYear()

    const formattedDate = {
      dayOfWeek,
      dayOfMonth,
      monthName,
      fullMonthName,
      year
    }

    return formattedDate
  }

  function SumQuantity(orderItem) {
    let quantity = 0

    orderItem.map((item) => (quantity += item.quantity))

    return quantity
  }

  return (
    <div>
      <PageTopPanel
        user={user}
        title={<Translate id='ORDERS_TITLE' />}
        subtitle={`${
          orderCount > 2
            ? orderCount + translate('ORDERS_FOUND')
            : orderCount + translate('ORDER_FOUND')
        }`}
        type='withoutadditional'
        // setOrderBy={setOrderBy}
        translate={translate}
      />
      <PageSection>
        <PageWrapper>
          {orders?.length === 0 ? (
            <OrderRow>
              <Translate id='NO_ORDERS' />
            </OrderRow>
          ) : (
            <>
              <TitleWrapper>
                <EachTitle>
                  <Translate id='ORDER' />
                </EachTitle>
                <EachTitle>
                  <Translate id='DATE' />
                </EachTitle>
                <EachTitle>
                  <Translate id='STATUS' />
                </EachTitle>
                <EachTitle>
                  <Translate id='DELIVERY' />
                </EachTitle>
                <EachTitle>
                  <Translate id='TOTAL' />
                </EachTitle>
              </TitleWrapper>
              {orders?.map((order) => (
                <>
                  <OrdersWrapper
                    key={order?.orderID}
                    open={
                      open.status && order?.orderID === open.id ? '1' : '0'
                    }
                  >
                    <OrderInformation>
                      <InfoItem
                        style={{
                          color:
                            user.configuration?.layoutColors?.contentText
                        }}
                      >
                        #
                        {order?.orderRelatedID > 0
                          ? order?.number
                          : order?.orderID}
                      </InfoItem>
                      <InfoItem>
                        {formatDate(order?.date).dayOfMonth}{' '}
                        {formatDate(order?.date).fullMonthName},{' '}
                        {formatDate(order?.date).year}
                      </InfoItem>
                      <InfoItem>
                        {order?.isPartially ? (
                          <Translate id='PARTIALLY' />
                        ) : (
                          ''
                        )}
                        {order?.isPartially ? ' ' : ''}
                        {
                          order?.orderStatus?.orderStatusTranslation?.find(
                            (x) => x.languageId === user.languageId
                          )?.name
                        }
                      </InfoItem>
                      <InfoItem>
                        <ProgressBar
                          showInfo={false}
                          percent={
                            order?.orderRelatedID === 0
                              ? // Regular flow
                                order?.orderStatus?.orderStatusId === 12
                                ? '0.00' // Pending
                                : order?.orderStatus?.orderStatusId === 1
                                ? '16.66' // Processing
                                : order?.orderStatus?.orderStatusId === 2
                                ? '33.33' // Out-of-stock
                                : order?.orderStatus?.orderStatusId === 3
                                ? '50.00' // Confirmed
                                : order?.orderStatus?.orderStatusId === 4
                                ? '66.66' // Shipped
                                : order?.orderStatus?.orderStatusId === 5
                                ? '83.33' // Delivered
                                : order?.orderStatus?.orderStatusId === 6
                                ? '100.00' // Cancelled
                                : '0.00'
                              : // Return / Exchange flow
                              order?.orderStatus?.orderStatusId === 7
                              ? '0.00' // Waiting for Package
                              : order?.orderStatus?.orderStatusId === 8
                              ? '25.00' // Package received
                              : order?.orderStatus?.orderStatusId === 9
                              ? '50.00' // Approved
                              : order?.orderStatus?.orderStatusId === 10
                              ? '75.00' // Denied
                              : order?.orderStatus?.orderStatusId === 11
                              ? '100.00' // Refund completed
                              : '0.00'
                          }
                          style={{width: '100%'}}
                        />
                      </InfoItem>
                      <>
                        {windowWidth <= 768 ? (
                          <>
                            {!open.status && (
                              <InfoItemDesktop
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between'
                                }}
                              >
                                <strong>
                                  <Translate id='ORDER_TOTAL' />
                                </strong>
                                {Currency.format(
                                  order?.usesCredits
                                    ? 0
                                    : order?.totalValue,
                                  order?.currencyCode
                                )}
                              </InfoItemDesktop>
                            )}
                          </>
                        ) : (
                          <InfoItemDesktop style={{width: 'auto'}}>
                            {Currency.format(
                              order?.usesCredits ? 0 : order?.totalValue,
                              order?.currencyCode
                            )}
                          </InfoItemDesktop>
                        )}
                      </>

                      <ExpandButton
                        open={
                          order?.orderID === open.id ? open.status : ''
                        }
                        onClick={() =>
                          setOpen({
                            id: order?.orderID,
                            status: !open.status
                          })
                        }
                        style={{
                          backgroundColor:
                            user.configuration?.layoutColors?.contentText
                        }}
                      />
                    </OrderInformation>
                  </OrdersWrapper>
                  <OrderSummary
                    open={
                      open.status && order?.orderID === open.id ? '1' : '0'
                    }
                  >
                    <OrderTable>
                      <OrderAside>
                        <OrderTableWrapper>
                          <OrderTableTitle>
                            <Translate id='DELIVERY_ADDRESS' />
                          </OrderTableTitle>
                          <OrderTableItems>
                            {order?.deliveryAddressLine1}
                            <br />
                            {order?.deliveryAddressLine2 !== '' ||
                            order?.deliveryAddressLine3 !== ''
                              ? order?.deliveryAddressLine2 +
                                ' ' +
                                order?.deliveryAddressLine3
                              : ''}
                            {order?.deliveryAddressLine2 !== '' ||
                            order?.deliveryAddressLine3 !== '' ? (
                              <br />
                            ) : (
                              ''
                            )}
                            {order?.deliveryStreetNumber !== ''
                              ? order?.deliveryStreetNumber
                              : ''}
                            {order?.deliveryStreetNumber !== '' ? (
                              <br />
                            ) : (
                              ''
                            )}
                            {order?.deliveryZipCode} {order?.deliveryCity}{' '}
                            {order?.deliveryRegion},{' '}
                            {order?.deliveryCountryName}
                          </OrderTableItems>
                          <OrderTableItems>
                            <ProblemMailLink
                              href={`mailto:info@wearskypro.com?subject=${translate(
                                'SUBJECT_PROBLEM_EMAIL'
                              )} #${
                                order?.orderRelatedID > 0
                                  ? order?.number
                                  : order?.orderID
                              }`}
                              style={{
                                color:
                                  user.configuration?.layoutColors
                                    ?.contentText
                              }}
                            >
                              <Translate id='PROBLEM_CONTACT' />
                            </ProblemMailLink>
                          </OrderTableItems>
                        </OrderTableWrapper>
                      </OrderAside>
                      <SummaryAside>
                        <OrderTableWrapper>
                          <OrderTableTitle>
                            <Translate id='ORDER_SUMMARY' />
                          </OrderTableTitle>
                          <SummaryTableItems>
                            <SummaryTitle style={{marginTop: 8}}>
                              <Translate id='ITEMS' />
                            </SummaryTitle>
                            <SummarySpan>
                              {SumQuantity(order?.orderItem)}
                            </SummarySpan>
                          </SummaryTableItems>
                          <SummaryTableItems>
                            <SummaryTitle>
                              <Translate id='POSTAGE_PACKING' />
                            </SummaryTitle>
                            <SummarySpan>
                              {Currency.format(0, order?.currencyCode)}
                            </SummarySpan>
                          </SummaryTableItems>
                          <SummaryTableItems>
                            <SummaryTitle style={{marginBottom: 0}}>
                              <strong>
                                <Translate id='ORDER_TOTAL' />
                              </strong>
                            </SummaryTitle>
                            <SummarySpan>
                              <strong>
                                {Currency.format(
                                  order?.usesCredits
                                    ? 0
                                    : order?.totalValue,
                                  order?.currencyCode
                                )}
                              </strong>
                            </SummarySpan>
                          </SummaryTableItems>
                        </OrderTableWrapper>
                      </SummaryAside>
                      <OrderStatusAside>
                        <HalfTable>
                          <OrderTableTitle>
                            <Translate id='DATE_SUBMISSION' />
                          </OrderTableTitle>
                          <OrderTableItems>
                            <SummaryTitle>
                              {formatDate(order?.date).dayOfMonth}{' '}
                              {formatDate(order?.date).monthName}{' '}
                              {formatDate(order?.date).year}
                            </SummaryTitle>
                          </OrderTableItems>
                        </HalfTable>
                        <HalfTable>
                          <OrderTableTitle>
                            <Translate id='ESTIMATED_DELIVERY' />
                          </OrderTableTitle>
                          <OrderTableItems>
                            <SummaryTitle>
                              {order?.dateDelivery
                                ? formatDate(order.dateDelivery)
                                    .dayOfMonth +
                                  ' ' +
                                  formatDate(order.date).monthName +
                                  ' ' +
                                  formatDate(order.date).year
                                : '-'}
                            </SummaryTitle>
                          </OrderTableItems>
                        </HalfTable>
                        <HalfTable>
                          <OrderTableTitle>
                            <Translate id='PAYMENT_STATE' />
                          </OrderTableTitle>
                          <OrderTableItems>
                            <SummaryTitle style={{marginBottom: 0}}>
                              {order.paid === 1 ? (
                                <Translate id='PAYMENT_RECEIVED' />
                              ) : (
                                <Translate id='PAYMENT_PENDING' />
                              )}
                            </SummaryTitle>
                          </OrderTableItems>
                        </HalfTable>
                        {order.paid === 0 ? (
                          <OrderTableWrapper>
                            <OrderTableTitle />
                            <OrderTableItems>
                              <ProblemMailLink
                                style={{
                                  color:
                                    user.configuration?.layoutColors
                                      ?.contentText
                                }}
                              >
                                <Translate id='GET_REFERENCE' />
                              </ProblemMailLink>
                            </OrderTableItems>
                          </OrderTableWrapper>
                        ) : (
                          ''
                        )}
                      </OrderStatusAside>
                    </OrderTable>
                    {order?.attachment.length > 0 ||
                    order?.notes !== '-' ||
                    order?.trackingList.length > 0 ? (
                      <OrderInfoTable>
                        <OrderInfoAside>
                          <OrderTableWrapper>
                            <OrderTableTitle>
                              <Translate id='DOCUMENTS' />
                            </OrderTableTitle>
                            <OrderTableItems>
                              {order?.attachment.length > 0
                                ? order?.attachment.map(
                                    (document, index) => (
                                      <LineText>
                                        <LinkText
                                          key={index}
                                          href={`/${document.name}`}
                                          target='_blank'
                                        >
                                          {document.name}
                                        </LinkText>
                                      </LineText>
                                    )
                                  )
                                : '-'}
                            </OrderTableItems>
                          </OrderTableWrapper>
                        </OrderInfoAside>
                        <OrderInfoAside>
                          <OrderTableWrapper>
                            <OrderTableTitle>
                              <Translate id='NOTES' />
                            </OrderTableTitle>
                            <OrderTableItems>
                              {order?.notes}
                            </OrderTableItems>
                          </OrderTableWrapper>
                        </OrderInfoAside>
                        <OrderInfoAside>
                          <OrderTableWrapper>
                            <OrderTableTitle>
                              <Translate id='TRACKING' />
                              {' #'}
                            </OrderTableTitle>
                            <OrderTableItems>
                              {order?.trackingList.length > 0
                                ? order?.trackingList.map(
                                    (tracking) => tracking
                                  )
                                : '-'}
                            </OrderTableItems>
                          </OrderTableWrapper>
                        </OrderInfoAside>
                      </OrderInfoTable>
                    ) : null}
                    <StatusBarBig>
                      <BigProgressBar
                        size='large'
                        current={order?.orderStatus?.orderStatusId}
                        progressDot
                      >
                        {order?.orderRelatedID === 0
                          ? regularDataSteps?.map((product, index) => (
                              <Step
                                key={index}
                                title={
                                  product?.orderStatusTranslation?.find(
                                    (x) => x.languageId === user.languageId
                                  )?.name
                                }
                              />
                            ))
                          : returnDataSteps?.map((product, index) => (
                              <Step
                                key={index}
                                title={
                                  product?.orderStatusTranslation?.find(
                                    (x) => x.languageId === user.languageId
                                  )?.name
                                }
                              />
                            ))}
                      </BigProgressBar>
                    </StatusBarBig>
                    {order?.orderItem?.map((product, index) => (
                      <>
                        <ProductOrderRow key={index}>
                          <OrderProduct>
                            <ProductThumb
                              src={GetImage(
                                product?.product?.productImage?.find(
                                  (x) => x.cover
                                )
                              )}
                            />
                          </OrderProduct>
                          <OrderProduct>
                            <ProductCategory
                              style={{
                                color:
                                  user.configuration?.layoutColors
                                    ?.contentText
                              }}
                            >
                              {
                                product?.product?.mainCategory?.categoryTranslation?.find(
                                  (x) => x.languageId === user.languageId
                                )?.name
                              }
                            </ProductCategory>
                            <ProductTitle>
                              {
                                product?.product?.productTranslation?.find(
                                  (x) => x.languageID === user.languageId
                                )?.name
                              }
                            </ProductTitle>
                          </OrderProduct>
                          <OrderProduct>
                            <ProductQuantity>
                              <Translate id='QUANTITY' />:{' '}
                              {product?.quantity}
                            </ProductQuantity>
                            <ProductQuantity>
                              {
                                product?.color?.colorTranslation?.find(
                                  (x) => x.languageId === user.languageId
                                )?.name
                              }
                              {' / '}
                              {product?.size?.nameEu}
                            </ProductQuantity>
                          </OrderProduct>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              width: '100%'
                            }}
                          >
                            <ProductStatus style={{marginBottom: 0}}>
                              <Translate id='STATUS' />:{' '}
                              {
                                product?.status?.orderStatusTranslation?.find(
                                  (x) => x.languageId === user.languageId
                                )?.name
                              }
                            </ProductStatus>
                            <ProductStatus />
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}
                            >
                              {product?.isCredits === 0 ? (
                                <ProductPrice>
                                  {Currency.format(
                                    product?.unitTotalValueFinal,
                                    order?.currencyCode
                                  )}
                                </ProductPrice>
                              ) : (
                                <ProductPrice style={{marginBottom: 0}}>
                                  {product?.quantity}{' '}
                                  {product?.quantity === 1 ? (
                                    <Translate id='CART_CREDIT' />
                                  ) : (
                                    <Translate id='CART_CREDITS' />
                                  )}
                                </ProductPrice>
                              )}
                            </div>
                          </div>
                        </ProductOrderRow>
                      </>
                    ))}
                  </OrderSummary>
                </>
              ))}
            </>
          )}
        </PageWrapper>
      </PageSection>
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: state.user
})

Orders.propTypes = {
  translate: PropTypes.func.isRequired
}

export default withLocalize(connect(mapStateToProps)(Orders))
