const getTranslationNameFromArray = (value, languageId, key = 'name') => {
  if (!value || !Array.isArray(value) || value.length === 0) return ''
  const found = value.find((elem) => elem.languageId === languageId)
  if (found) return found[key]
  const en = value.find(
    (elem) => elem.languageID === 2 || elem.languageId === 2
  )
  if (en) return en[key]
  return value[0][key]
}

export default getTranslationNameFromArray
