import React, {useEffect, useState} from 'react'

import {Avatar} from 'antd'
import _ from 'lodash'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'
import {connect} from 'react-redux'

import LocaleString from '../../infra/constants/LocaleString'
import {GetAllCountries} from '../../infra/requests/AddressRequests'
import {
  GetAvailableSlots,
  GetDefaultSlot,
  GetLastFittingDate,
  ScheduleFittingAppointment
} from '../../infra/requests/FittingRequests'
import {GetAllProducts} from '../../infra/requests/ProductRequests'
import BaseLoading from '../../shared/components/loading/BaseLoading'
import PageTopPanel from '../../shared/components/panels/PageTopPanel'
import FittingSlider from '../../shared/components/slider/FittingSlider'
import {
  DefaultSlotDetail,
  DefaultSlotDetailAside,
  DefaultSlotEmployeeRow,
  DefaultSlotFittingCenterElement,
  DefaultSlotFittingCenterList,
  DefaultSlotHeader,
  DefaultSlotHeaderSpan,
  DefaultSlotId,
  DefaultSlotResume,
  DefaultSlotResumeBox,
  DefaultSlotWrapper,
  EmployeeInfo,
  FittingSelectionWrapper,
  InfoEmphasized,
  InfoText,
  NextFittingDate,
  NextFittingDateTitle,
  NextFittingDateWrapper,
  NextFittingHour,
  NextFittingTitle,
  RescheduleBtn,
  ScheduleContainer,
  SelectButton
} from './FittingStyle'

const ScheduleFitting = ({user, products}) => {
  const [slotList, setSlotList] = useState([])
  const [loading, setLoading] = useState(true)
  const [selected, setSelected] = useState()
  const [hours, setHours] = useState(false)
  const [selectedDay, setSelectedDay] = useState()
  const [toSchedule, setToSchedule] = useState(false)
  const [itemsInOutfit, setItemsInOutfit] = useState()
  const [countryList, setCountryList] = useState([])
  const [lastFittingDate, setLastFittingDate] = useState()

  const {languageId} = user

  useEffect(() => {
    const GetListOfSlots = async () => {
      const {data, success} = await GetAvailableSlots()

      if (success) {
        const result = await GetDefaultSlot()

        if (result.success) {
          setSelected(result.data === 'No slot selected' ? 0 : result.data)
          setSlotList(
            data.sort((a, b) => new Date(a.slot) - new Date(b.slot)) || []
          )
          setSelectedDay(
            data
              .find((x) => x.fittingScheduleId === result.data)
              ?.slot?.split('T')[0] || data[0]?.slot?.split('T')[0]
          )

          if (result.data !== 'No slot selected') {
            setToSchedule(true)
          }
        }
      }

      const handleProducts = async () => {
        try {
          const result = await GetAllProducts()

          if (result.success) {
            setItemsInOutfit(result.data.items.length)
          }
        } catch (error) {
          console.log(error)
        }
      }
      await handleProducts()
      setLoading(false)
    }

    const GetLastFitting = async () => {
      const {data, success} = await GetLastFittingDate()

      if (success) {
        const last = new Date(data)

        const day = String(last.getDate()).padStart(2, '0')
        const month = String(last.getMonth() + 1).padStart(2, '0')
        const year = last.getFullYear()

        const latestFitting = `${day}-${month}-${year}`

        setLastFittingDate(latestFitting)
      }
    }

    const GetCountries = async () => {
      const {data, success} = await GetAllCountries()

      if (success) {
        setCountryList(data)
      }
    }
    GetLastFitting()
    GetListOfSlots()
    GetCountries()
  }, [products])

  const handleSelection = (value) => {
    setSelected(value)
  }

  const handleArr = (arr) => {
    const newArr = []
    for (let i = 0; i < arr.length; i++) {
      newArr.push({
        ...arr[i],
        slotDay: arr[i].slot.split('T')[0],
        slotTime: arr[i].slot.split('T')[1]
      })
    }

    return newArr
  }
  const a = handleArr(slotList)

  const grouped = _.toArray(
    _.mapValues(_.groupBy(a, 'slotDay'), (slots) =>
      slots.map((slot) => slot)
    )
  )

  const scheduleFittingAppointment = async () => {
    const result = await ScheduleFittingAppointment(selected)

    if (result.success) {
      window.location.reload(false)
    }
  }

  const getDateDay = (number) => {
    switch (number) {
      case 0:
        return 'Sun'
      case 1:
        return 'Mon'
      case 2:
        return 'Tue'
      case 3:
        return 'Wed'
      case 4:
        return 'Thu'
      case 5:
        return 'Fri'
      case 6:
        return 'Sat'
      default:
        return ''
    }
  }

  const getMonthName = (month) => {
    const date = new Date()
    date.setMonth(month)
    const monthName = date
      .toLocaleString(LocaleString[languageId].value, {
        month: 'short'
      })
      .replace('.', '')
    return monthName
  }

  const formatDate = (obj) => {
    const date = new Date(obj?.slot)
    const month = date.getMonth()
    const monthName = getMonthName(month)
    const dayOfWeek = getDateDay(date.getDay())
    const dayOfMonth = date.getDate()

    const formattedDate = {
      dayOfWeek,
      dayOfMonth,
      monthName
    }

    return formattedDate
  }

  if (loading) {
    return <BaseLoading />
  }

  return (
    <div>
      <PageTopPanel
        user={user}
        title={<Translate id='SCHEDULE_FITTING_TITLE' />}
        subtitle={<Translate id='SCHEDULE_FITTING_SUBTITLE' />}
        type='withoutadditional'
      />
      {toSchedule && (
        <DefaultSlotResume>
          <DefaultSlotWrapper>
            <DefaultSlotResumeBox>
              <DefaultSlotHeader>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  <DefaultSlotHeaderSpan>
                    <Translate id='REF_FITTING' />
                  </DefaultSlotHeaderSpan>
                  <DefaultSlotId>#{selected}</DefaultSlotId>
                </div>
                <RescheduleBtn onClick={() => setToSchedule(!toSchedule)}>
                  <Translate id='RESCHEDULE' />
                </RescheduleBtn>
              </DefaultSlotHeader>
              <DefaultSlotEmployeeRow>
                <div
                  style={{display: 'flex', gap: 10, alignItems: 'center'}}
                >
                  <EmployeeInfo>
                    <Avatar
                      size={45}
                      style={{
                        backgroundColor: '#8ab7e9'
                      }}
                    >
                      {user.fullName.split(' ', 2)[0][0].toUpperCase()}
                      {user.fullName.split(' ', 2)[1][0].toUpperCase()}
                    </Avatar>
                  </EmployeeInfo>
                  <EmployeeInfo>
                    {user.fullName.split(' ', 2)[0]}{' '}
                    {user.fullName.split(' ', 2)[1]}
                  </EmployeeInfo>
                </div>
                <EmployeeInfo>
                  <InfoEmphasized>
                    <Translate id='ITEMS_OUTFIT' />:
                  </InfoEmphasized>
                  <InfoText>{itemsInOutfit}</InfoText>
                </EmployeeInfo>
                {lastFittingDate === 'NaN-NaN-NaN' ? undefined : (
                  <EmployeeInfo>
                    <InfoEmphasized>
                      <Translate id='LAST_FITTING' />:
                    </InfoEmphasized>
                    {lastFittingDate}
                  </EmployeeInfo>
                )}
              </DefaultSlotEmployeeRow>
              <DefaultSlotDetail>
                <DefaultSlotDetailAside>
                  <DefaultSlotFittingCenterList>
                    <DefaultSlotFittingCenterElement>
                      {
                        slotList.find(
                          (x) => x.fittingScheduleId === selected
                        )?.contactDetail?.name
                      }
                    </DefaultSlotFittingCenterElement>
                    <DefaultSlotFittingCenterElement>
                      {
                        slotList.find(
                          (x) => x.fittingScheduleId === selected
                        )?.contactDetail?.addressLine1
                      }{' '}
                      {
                        slotList.find(
                          (x) => x.fittingScheduleId === selected
                        ).contactDetail.streetNumber
                      }
                      ,{' '}
                      {
                        slotList.find(
                          (x) => x.fittingScheduleId === selected
                        )?.contactDetail?.zipCode
                      }{' '}
                      {
                        slotList.find(
                          (x) => x.fittingScheduleId === selected
                        )?.contactDetail?.city
                      }
                      ,{' '}
                      {
                        slotList.find(
                          (x) => x.fittingScheduleId === selected
                        )?.contactDetail?.region
                      }
                      ,{' '}
                      {
                        countryList.find(
                          (x) =>
                            x.CountryId ===
                            slotList.find(
                              (y) => y.fittingScheduleId === selected
                            )?.contactDetail?.countryId
                        )?.Name
                      }
                    </DefaultSlotFittingCenterElement>
                    <DefaultSlotFittingCenterElement>
                      <Translate id='TELEPHONE' />:{' '}
                      {
                        slotList.find(
                          (x) => x.fittingScheduleId === selected
                        ).contactDetail.telephone
                      }
                    </DefaultSlotFittingCenterElement>
                  </DefaultSlotFittingCenterList>
                </DefaultSlotDetailAside>
                <DefaultSlotDetailAside>
                  <NextFittingTitle>
                    <Translate id='NEXT_FITTING' />
                  </NextFittingTitle>
                  <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    <NextFittingDateWrapper>
                      <NextFittingDateTitle>
                        <Translate id='DATE' />
                      </NextFittingDateTitle>
                      <NextFittingDate>
                        {
                          formatDate(
                            slotList.find(
                              (x) => x.fittingScheduleId === selected
                            )
                          ).dayOfWeek
                        }{' '}
                        {
                          formatDate(
                            slotList.find(
                              (x) => x.fittingScheduleId === selected
                            )
                          ).dayOfMonth
                        }{' '}
                        {
                          formatDate(
                            slotList.find(
                              (x) => x.fittingScheduleId === selected
                            )
                          ).monthName
                        }
                      </NextFittingDate>
                    </NextFittingDateWrapper>
                    <NextFittingHour>
                      <NextFittingDateTitle>
                        <Translate id='HOUR' />
                      </NextFittingDateTitle>
                      <NextFittingDate>
                        {String(
                          new Date(
                            slotList.find(
                              (x) => x.fittingScheduleId === selected
                            ).slot
                          ).getHours()
                        ).padStart(2, '0')}
                        :
                        {String(
                          new Date(
                            slotList.find(
                              (x) => x.fittingScheduleId === selected
                            ).slot
                          ).getMinutes()
                        ).padStart(2, '0')}
                      </NextFittingDate>
                    </NextFittingHour>
                  </div>
                </DefaultSlotDetailAside>
              </DefaultSlotDetail>
            </DefaultSlotResumeBox>
          </DefaultSlotWrapper>
        </DefaultSlotResume>
      )}
      {!toSchedule && (
        <ScheduleContainer>
          <FittingSelectionWrapper>
            <FittingSlider
              user={user}
              slides={slotList}
              selected={selected}
              setSelected={handleSelection}
              slidesOrg={grouped}
              setHours={setHours}
              hours={hours}
              selectedDay={selectedDay}
              setSelectedDay={setSelectedDay}
              countryList={countryList}
            />
            <SelectButton onClick={scheduleFittingAppointment}>
              Continue
            </SelectButton>
          </FittingSelectionWrapper>
        </ScheduleContainer>
      )}
    </div>
  )
}

ScheduleFitting.propTypes = {
  user: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired
}

const mapStateToProps = (state) => ({
  user: state.user,
  products: state.products
})

export default connect(mapStateToProps)(ScheduleFitting)
