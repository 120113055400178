import {bagSave} from '../../redux/data/bag/BagActions'
import {
  DecreaseBagItemQuantity,
  DeleteBagItem,
  IncreaseBagItemQuantity
} from '../requests/BagRequests'

export async function removeProductFromBag(productId, dispatch) {
  const {success, data} = await DeleteBagItem(productId)

  if (success) {
    dispatch(bagSave(data))
  }
}

export async function decreaseQuantity(bagItemId, dispatch, languageId) {
  const {success, data} = await DecreaseBagItemQuantity(
    bagItemId,
    languageId
  )

  if (success) {
    dispatch(bagSave(data))
  }
}

export async function increaseQuantity(bagItemId, dispatch, languageId) {
  const {success, data} = await IncreaseBagItemQuantity(
    bagItemId,
    languageId
  )

  if (success) {
    dispatch(bagSave(data))
  }
}
