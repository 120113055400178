import React, {useState} from 'react'

import {Col, Row, Tooltip} from 'antd'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {useDispatch, useSelector} from 'react-redux'

import LocaleString from '../../../infra/constants/LocaleString'
import {removeProductFromBag} from '../../../infra/utils/BagActions'
import QuickAddToBagButton from '../../../shared/components/buttons/QuickAddToBagButton'
import GetImage from '../../../shared/components/Image'
import ComponentLoadingOverlay from '../../../shared/components/loading/ComponentLoadingOverlay'
import {
  ImageLink,
  BottomPanel,
  Category,
  CreditsProductCardPanel,
  DurationTag,
  DurationValue,
  ImageP,
  MiddlePanel,
  ProductName,
  SubTagBot,
  TagBot,
  TagTop,
  TopPanel,
  Workgroup,
  Wrapper,
  ExtraCreditsTag
} from './CreditsProductCardStyle'

const CreditsProductCard = ({user, product, fastAddToBag}) => {
  const [isLoading, setIsLoading] = useState(false)

  const extraCredits = product?.extraCreditsAvailable
  const {bagItems} = useSelector((state) => state.bag)
  const dispatch = useDispatch()

  const {languageId} = user

  const bagItem = bagItems.find(
    (item) => item.productId === product.productId
  )

  function FormatDate(data) {
    const date = new Date(data)
    const day = String(date.getDate()).padStart(2, '0')
    const monthName = date
      .toLocaleString(LocaleString[user.languageId].value, {
        month: 'short'
      })
      .replace('.', '')
    const year = date.getFullYear()
    const newDate = `${day} ${monthName} ${year}`

    return newDate
  }

  const handleFastAddClick = async () => {
    setIsLoading(true)
    if (bagItem) {
      await removeProductFromBag(bagItem.bagItemId, dispatch)
    } else {
      await fastAddToBag(product)
    }
    setIsLoading(false)
  }

  return (
    <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={6}>
      <ComponentLoadingOverlay isLoading={isLoading}>
        <CreditsProductCardPanel>
          <TopPanel>
            <QuickAddToBagButton
              handleFastAddClick={handleFastAddClick}
              bagItem={bagItem}
              user={user}
              unisize={product?.product?.productCombination?.length === 1}
            />
            <Category
              style={{
                color: user.configuration?.layoutColors?.contentText
              }}
            >
              {
                product?.product?.mainCategory?.categoryTranslation?.find(
                  (x) => x.languageId === languageId
                )?.name
              }
            </Category>
            <ProductName>
              {
                product?.product?.productTranslation?.find(
                  (x) => x.languageId === languageId
                )?.name
              }
            </ProductName>
            <Workgroup>{product?.employee?.position?.name}</Workgroup>
          </TopPanel>
          <MiddlePanel>
            <ImageLink to={`/products/${product?.productId}`}>
              <ImageP
                src={GetImage(
                  product?.product?.productImage?.find((x) => x.cover)
                )}
                alt={
                  product?.product?.productImage?.find((x) => x.cover)
                    ?.image?.filename
                }
              />
            </ImageLink>
          </MiddlePanel>
          <BottomPanel>
            <Row gutter={[0, 0]}>
              <Col xs={12}>
                <Row gutter={[0, 0]}>
                  <Wrapper>
                    <Col xs={24}>
                      <TagTop
                        style={{
                          color:
                            user.configuration?.layoutColors?.contentText
                        }}
                      >
                        <Translate id='CREDITS_LEFT' />
                      </TagTop>
                    </Col>
                    <Col xs={24}>
                      <TagBot>
                        {product?.creditsAvailable}&nbsp;
                        <SubTagBot>
                          <Translate id='OF' />
                        </SubTagBot>
                        &nbsp;
                        {product?.dotation}
                        {extraCredits > 0 && (
                          <ExtraCreditsTag>{`(+${extraCredits})`}</ExtraCreditsTag>
                        )}
                      </TagBot>
                    </Col>
                  </Wrapper>
                </Row>
              </Col>
              <Col xs={12}>
                <TagTop
                  style={{
                    color: user.configuration?.layoutColors?.contentText
                  }}
                >
                  <Translate id='RENEWS_ON' />
                </TagTop>
                <TagBot>
                  {product?.renovationDate === '0001-01-01T00:00:00'
                    ? '-'
                    : FormatDate(product?.renovationDate)}
                </TagBot>
              </Col>
              <Col xs={12}>
                <DurationTag
                  style={{
                    color: user.configuration?.layoutColors?.contentText
                  }}
                >
                  <Translate id='DURATION' />
                </DurationTag>
              </Col>
              <Col xs={12}>
                <DurationValue>
                  {Math.round(product?.productDuration / 30)}{' '}
                  <Translate id='MONTHS' />
                </DurationValue>
              </Col>
            </Row>
          </BottomPanel>
        </CreditsProductCardPanel>
      </ComponentLoadingOverlay>
    </Col>
  )
}

CreditsProductCard.propTypes = {
  product: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  fastAddToBag: PropTypes.func.isRequired
}

export default withLocalize(CreditsProductCard)
