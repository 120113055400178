import React, {useState} from 'react'

import {Col, Row, Spin} from 'antd'
import {PropTypes} from 'prop-types'
import {Translate} from 'react-localize-redux'
import {useDispatch} from 'react-redux'

import {AddAllItemsToBag} from '../../../infra/requests/BagRequests'
import {bagSave} from '../../../redux/data/bag/BagActions'
import {
  TitleLabel,
  SubTitleLabel,
  TopBar,
  Align,
  OrderBtn,
  SortBySelect,
  SelectOption
} from './PageTopPanelStyle'

const PageTopPanel = ({
  user,
  title,
  subtitle,
  type,
  setOrderBy,
  translate
}) => {
  const [isHover, setIsHover] = useState(false)
  const [isAddingAll, setIsAddingAll] = useState(false)
  const dispatch = useDispatch()

  const handleMouseEnter = () => {
    setIsHover(true)
  }

  const handleMouseLeave = () => {
    setIsHover(false)
  }

  async function AddAllToBag(languageId) {
    setIsAddingAll(true)
    const {success, data} = await AddAllItemsToBag(languageId)

    if (success) {
      dispatch(bagSave(data))
    }
    setIsAddingAll(false)
  }

  const btnStyle = {
    transition: 'all 0.5s ease',
    color: isHover
      ? user.configuration?.layoutColors?.headerButtonText
      : user.configuration?.layoutColors?.headerText,
    border: `1px solid ${user.configuration?.layoutColors?.headerText}`,
    backgroundColor: isHover
      ? user.configuration?.layoutColors?.headerText
      : 'transparent'
  }

  return type === 'withoutadditional' ? (
    <TopBar
      style={{
        backgroundColor: user.configuration?.layoutColors?.secondary,
        color: user.configuration?.layoutColors?.headerText
      }}
    >
      <Row gutter={[0, 0]} className='row'>
        <Col xs={1} />
        <Col xs={22}>
          <TitleLabel
            style={{
              color: user.configuration?.layoutColors?.headerTitleText
            }}
          >
            {title}
          </TitleLabel>
          <SubTitleLabel>{subtitle}</SubTitleLabel>
        </Col>
        <Col xs={1} />
      </Row>
    </TopBar>
  ) : type === 'withbutton' ? (
    <TopBar
      style={{
        backgroundColor: user.configuration?.layoutColors?.secondary,
        color: user.configuration?.layoutColors?.headerText
      }}
    >
      <Row gutter={[0, 0]} className='row'>
        <Col xs={1} lg={1} />
        <Col xs={22} lg={10}>
          <TitleLabel
            style={{
              color: user.configuration?.layoutColors?.headerTitleText
            }}
          >
            {title}
          </TitleLabel>
          {subtitle && <SubTitleLabel>{subtitle}</SubTitleLabel>}
        </Col>
        <Col xs={1} lg={1} />
        <Col xs={1} lg={1} />
        <Col xs={22} lg={10}>
          <Align>
            <OrderBtn
              onClick={() => AddAllToBag(user.languageId)}
              style={btnStyle}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              loading={isAddingAll}
            >
              {isAddingAll && (
                <div
                  style={{
                    marginRight: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingBlockStart: 5
                  }}
                >
                  <Spin />
                </div>
              )}
              <Translate id='ORDER_ALL_OUTFIT' />
            </OrderBtn>
          </Align>
        </Col>
        <Col xs={1} lg={1} />
      </Row>
    </TopBar>
  ) : type === 'withdrop' ? (
    <TopBar
      style={{
        backgroundColor: user.configuration?.layoutColors?.secondary,
        color: user.configuration?.layoutColors?.headerText
      }}
    >
      <Row gutter={[0, 0]} className='row'>
        <Col xs={1} lg={1} />
        <Col xs={22} lg={10}>
          <TitleLabel
            style={{
              color: user.configuration?.layoutColors?.headerTitleText
            }}
          >
            {title}
          </TitleLabel>
          <SubTitleLabel>{subtitle}</SubTitleLabel>
        </Col>
        <Col xs={1} lg={1} />
        <Col xs={1} lg={1} />
        <Col xs={22} lg={10}>
          <Align>
            <SortBySelect defaultValue='SortBy'>
              <SelectOption value='SortBy'>
                {translate('SORT_BY')}
              </SelectOption>
              <SelectOption
                value='Order'
                onClick={() => setOrderBy('OrderID')}
              >
                {translate('ORDER')}
              </SelectOption>
              <SelectOption
                value='Date'
                onClick={() => setOrderBy('Date')}
              >
                {translate('DATE')}
              </SelectOption>
              <SelectOption
                value='Status'
                onClick={() => setOrderBy('Status')}
              >
                {translate('STATUS')}
              </SelectOption>
              <SelectOption
                value='Total'
                onClick={() => setOrderBy('Total')}
              >
                {translate('TOTAL')}
              </SelectOption>
            </SortBySelect>
          </Align>
        </Col>
        <Col xs={1} lg={1} />
      </Row>
    </TopBar>
  ) : undefined
}

PageTopPanel.propTypes = {
  user: PropTypes.object.isRequired,
  title: PropTypes.object.isRequired,
  subtitle: PropTypes.object,
  type: PropTypes.string,
  translate: PropTypes.func,
  setOrderBy: PropTypes.func
}

PageTopPanel.defaultProps = {
  type: undefined,
  translate: () => {},
  setOrderBy: () => {},
  subtitle: undefined
}

export default PageTopPanel
